import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    backgroundColor: "black",
    marginTop: "73px"
  },
  link: {
    textDecoration: "none"
  },
  content: {
    margin: "0 20px 0 20px"
  },
  title: {
    color: "#ea5055",
    fontSize: "1.3rem",
    fontWeight: "bold",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem"
    }
  },
  text: {
    color: "#60adb5",
    fontSize: "1rem",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.5rem"
    }
  },
  img: {
    maxHeight: "30px",
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    }
  },
  dottedLine: {
    borderBottom: "2px dashed #60adb5",
    width: "100%",
    margin: "5px 0 5px 0"
  }
});

class NewsBanner extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <a
          href="https://www.sido-event.com/exposants/fiche/1286/denimbo.html"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Grid container className={classes.container}>
            <div className={classes.dottedLine} />
            <Grid
              item
              container
              alignItems="center"
              className={classes.content}
            >
              <Grid item xs={8} sm={10}>
                <p className={classes.title}>
                  Meet us at SIDO !
                  <span className={classes.text}>
                    {"  "}# EUROPE’S LEADING IoT, AI & ROBOTICS EVENT
                  </span>
                </p>
              </Grid>
              <Grid item xs={4} sm={2}>
                <img
                  className={classes.img}
                  src="images/sido.png"
                  alt="Sido event logo"
                />
              </Grid>
            </Grid>
            <div className={classes.dottedLine} />
          </Grid>
        </a>
      </Fragment>
    );
  }
}

export default withStyles(styles)(NewsBanner);
