import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const styles = theme => ({
  container: {
    backgroundColor: "#F6F4F4",
    paddingBottom: theme.spacing.unit * 10,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing.unit * 2
    }
  },
  title: {
    padding: theme.spacing.unit * 8,
    fontWeight: "bold",
    color: "#FCB348",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw"
    }
  },
  paragraph: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5vw"
    },
    lineHeight: 1.5
  }
});

class Diagram extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} justify="space-around">
        <Fade bottom distance={"200px"} duration={750}>
          <Grid item xs className={classes.title}>
            From device to Cloud
          </Grid>
          <Grid item container justify="space-around">
            <Grid
              item
              xs={12}
              sm={5}
              container
              direction="column"
              justify="space-between"
            >
              <img
                src={"images/skavenji_diagram.png"}
                alt="Diagram of the full Skavenji application"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <h2>A multitier application</h2>
              <p className={classes.paragraph}>
                The Skavenji solution has been fully developed using the denimbo
                IoT suite, from the Box firmware to the Cloud Service
                connectors.
              </p>
              <h2>A direct link to the box</h2>
              <p className={classes.paragraph}>
                The Skavenji Box embedded application controls the production
                and consumption of energy.
                <br /> The application is remotely managed from the user’s smart
                device.
              </p>
              <h2>Cloud connected</h2>
              <p className={classes.paragraph}>
                The Box is connected to Cloud Services, allowing the user to
                share production information with peers from everywhere.
              </p>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    );
  }
}

export default withStyles(styles)(Diagram);
