import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { HashLink as Link } from "react-router-hash-link";

import MediaCard from "../utils/MediaCard.js";
import MyPaper from "../utils/MyPaper.js";
import OffsetAnchor from "../utils/OffsetAnchor.js";
import CustomButton from "../utils/CustomButton.js";
import googleEvent from "../utils/GoogleEvent.js";

const styles = theme => ({
  title: {
    color: "#FF6600",
    lineHeight: 1.5,
    textAlign: "justify"
  },
  link: {
    color: "#FF6600",
    padding: "0 0 32px 32px",
    fontSize: "1.3rem"
  }
});

class Achievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      a: {
        img: "images/angmed.jpeg",
        alt: "Conected rehabilitation device using the denimbo IoT suite",
        title: "Connected Rehabilitation Device",
        list: [
          "It took only 6 weeks to develop a running prototype of the full application able to run in standalone mode as well as controlled from a nearby tablet or connected to the Cloud to retrieve the user profile or store activity data."
        ]
      },
      b: {
        img: "images/nao.jpeg",
        alt: "Connected robot coach using the denimbo IoT suite",
        title: "Connected robot coach",
        list: [
          "This humanoid robot is configured to stimulate the interaction capabilities of patients. The application combines embedded services and cloud hosted ones, which are triggered when needed."
        ]
      },
      c: {
        img: "images/kinect.png",
        alt: "Patient monitoring using the denimbo IoT suite",
        title: "Patient monitoring",
        list: [
          "Imaging sensors and Artificial Intelligence algorithms have been combined with the denimbo framework to build a connected application for patient monitoring and diagnostic."
        ]
      }
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <OffsetAnchor id={this.props.id} />
        <MyPaper>
          <Grid container justify="space-evenly" spacing={16}>
            <Grid item container xs={12} sm={11}>
              <h3 className={classes.title}>
                The denimbo IoT suite is being used in various contexts, such as
                connected robots, healthcare services, or in the energy field to
                implement automated systems for producing electricity.
              </h3>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard content={this.state.a} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard content={this.state.b} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard content={this.state.c} />
            </Grid>
          </Grid>
          <Grid container>
            <Link
              to="/example-use#scavTop"
              className={classes.link}
              onClick={function() {
                googleEvent("innerLink", "achievementsToCustomer");
              }}
            >
              More denimbo applications
            </Link>
          </Grid>
          <Grid container justify={"center"}>
            <CustomButton
              link="mailto:contact@denimbo.com"
              content={"Contact"}
              onclick="achivementsContact"
            />
          </Grid>
        </MyPaper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Achievements);
