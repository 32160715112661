import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";

import Skavenji from "./Skavenji.js";
import Diagram from "./Diagram.js";
import Agility from "./Agility.js";
import Design from "./Design.js";

import OrangeDivider from "../utils/OrangeDivider.js";
import OffsetAnchor from "../utils/OffsetAnchor.js";

class UseCase extends Component {
  render() {
    return (
      <Fragment>
        <Helmet
          script={[
            {
              type: "application/ld+json",
              innerHTML: `{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Use Case",
                "item": "https://denimbo.com/example-use"
              }]
            }`
            }
          ]}
        >
          <title>denimbo - Example</title>
          <meta
            name="description"
            content="A customer solution built with the denimbo IoT suite"
          />
        </Helmet>
        <OffsetAnchor id="scavTop" />
        <Skavenji />
        <OrangeDivider />
        <Diagram />
        <OrangeDivider />
        <Agility />
        <OrangeDivider />
        <Design />
      </Fragment>
    );
  }
}

export default UseCase;
