import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import googleEvent from "../utils/GoogleEvent.js";

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 60
  },
  bar: {
    backgroundColor: "#EEECEA"
  },
  grow: {
    flexGrow: 1,
    textAlign: "left"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  list: {
    display: "flex",
    flexDirection: "row"
  },
  listItem: {
    width: "auto",
    padding: "1.1vw"
  },
  link: {
    color: "black",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1vw"
    },
    textDecoration: "none",
    position: "relative",
    "&::before": {
      content: "''",
      color: "black",
      position: "absolute",
      width: "100%",
      height: "2px",
      bottom: 0,
      left: 0,
      backgroundColor: "#FF6600",
      visibility: "hidden",
      transform: "scaleX(0)",
      transition: theme.transitions.create(["visibility", "transform"], {
        duration: theme.transitions.duration.complex
      })
    },
    "&:hover": {
      color: "#FF6600"
    },
    "&:hover:before": {
      visibility: "visible",
      transform: "scaleX(1)"
    }
  },
  selected: {
    color: "red"
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  drawer: {
    width: 240,
    flexShrink: 0
  },
  drawerPaper: {
    width: 240
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  }
});

class MyNavbar extends Component {
  state = {
    open: false
  };

  closeAndGA = (action, category) => {
    this.handleDrawerClose();
    googleEvent(action, category);
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleScroll = el => {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    let itemsInfos = [
      {
        title: "Home",
        link: "/#landTop",
        googleEvent: "home"
      },
      {
        title: "About denimbo",
        link: "/#about",
        googleEvent: "about"
      },
      {
        title: "Benefits",
        link: "/#benefits",
        googleEvent: "benefits"
      },
      {
        title: "Vision",
        link: "/#vision",
        googleEvent: "vision"
      },
      {
        title: "Achievements",
        link: "/#achievements",
        googleEvent: "achievements"
      },
      {
        title: "Get Started",
        link: "/IoT-shopping-list#keyTop",
        googleEvent: "goingIoT"
      },
      {
        title: "A customer case",
        link: "/example-use#scavTop",
        googleEvent: "customerCase"
      },
      {
        title: "Partners",
        link: "/partners#partTop",
        googleEvent: "partners"
      }
    ];

    let listItems = itemsInfos.map((item, i) => {
      return (
        <ListItem className={classes.listItem} key={i}>
          <NavLink
            to={item.link}
            scroll={this.handleScroll}
            className={classes.link}
            onClick={function() {
              googleEvent("Link", "Navbar", item.googleEvent);
            }}
          >
            {item.title}
          </NavLink>
        </ListItem>
      );
    });

    let mobileListItems = itemsInfos.map((item, i) => {
      return (
        <NavLink
          to={item.link}
          scroll={this.handleScroll}
          className={classes.link}
          key={i}
          onClick={() =>
            this.closeAndGA("Link", "mobileMenu", item.googleEvent)
          }
        >
          <ListItem divider>{item.title}</ListItem>
        </NavLink>
      );
    });

    return (
      <div className={classes.root}>
        <AppBar className={classes.bar} position="fixed">
          <Toolbar>
            <div className={classes.grow}>
              <Button color="inherit">
                <NavLink
                  to="/#landTop"
                  scroll={this.handleScroll}
                  onClick={function() {
                    googleEvent("Link", "Navbar", "denimboLogo");
                  }}
                >
                  <img
                    src="images/denimbo.png"
                    alt="denimbo logo"
                    height="50px"
                  />
                </NavLink>
              </Button>
            </div>

            <div className={classes.sectionDesktop}>
              <List className={classes.list}>{listItems}</List>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                color="inherit"
                aria-label="Menu"
                className={classes.menuButton}
                onClick={open ? this.handleDrawerClose : this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <List>{mobileListItems}</List>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MyNavbar));
