import React from "react";

const Divider = () => (
  <hr
    style={{
      border: 0,
      width: "75%",
      height: "1px",
      backgroundColor: "#E9E9E9",
      marginRight: "15%"
    }}
  />
);

export default Divider;
