import React from "react";

const OrangeDivider = () => (
  <hr
    style={{
      border: 0,
      width: "100%",
      height: "10px",
      backgroundColor: "#FCB348",
      margin: 0
    }}
  />
);

export default OrangeDivider;
