import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import text from "./TextContent.js";
import Divider from "../utils/Divider";

const styles = theme => ({
  container: {
    paddingBottom: "30px"
  },
  tabs: {
    display: "flex",
    flexDirection: "column"
  },
  solution: {
    textAlign: "left",
    lineHeight: "1.5",
    color: "#3F3F3F",
    fontSize: "1.3vw"
  },
  solutionPaper: {
    minHeight: "90%",
    padding: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 4
  }
});

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: "column"
  },
  indicator: {
    display: "none"
  }
}))(Tabs);

const MyTab = withStyles(theme => ({
  root: {
    textAlign: "left",
    minWidth: "95%",
    "&:hover": {
      backgroundColor: "#F7F7F7"
    }
  },
  wrapper: {
    display: "inline-flex",
    flexDirection: "row-reverse"
  },
  selected: {
    color: "orange"
  }
}))(Tab);

class ProblemsDesktop extends Component {
  state = {
    value: text.question.TabOne
  };

  handleChange = (event, value) => {
    window.gtag("config", "UA-132067546-1", {
      anonymize_ip: true,
      page_title: "IoT-shopping-list/",
      page_location: window.location.href,
      page_path: "IoT-shopping-list/" + value.props.children
    });
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Grid container justify={"center"} className={classes.container}>
        <Grid item xs={4}>
          <VerticalTabs value={value} onChange={this.handleChange}>
            <MyTab
              value={text.question.TabOne}
              label={text.question.TabOne}
              icon={<ChevronRightIcon />}
            />
            <Divider />
            <MyTab
              value={text.question.TabTwo}
              label={text.question.TabTwo}
              icon={<ChevronRightIcon />}
            />
            <Divider />
            <MyTab
              value={text.question.TabThree}
              label={text.question.TabThree}
              icon={<ChevronRightIcon />}
            />
            <Divider />
            <MyTab
              value={text.question.TabFour}
              label={text.question.TabFour}
              icon={<ChevronRightIcon />}
            />
            <Divider />
            <MyTab
              value={text.question.TabFive}
              label={text.question.TabFive}
              icon={<ChevronRightIcon />}
            />
          </VerticalTabs>
        </Grid>
        <Grid item xs={7}>
          <Paper elevation={1} className={classes.solutionPaper}>
            {value === text.question.TabOne && (
              <div className={classes.solution}>{text.solution.InputOne}</div>
            )}
            {value === text.question.TabTwo && (
              <div className={classes.solution}>{text.solution.InputTwo}</div>
            )}
            {value === text.question.TabThree && (
              <div className={classes.solution}>{text.solution.InputThree}</div>
            )}
            {value === text.question.TabFour && (
              <div className={classes.solution}>{text.solution.InputFour}</div>
            )}
            {value === text.question.TabFive && (
              <div className={classes.solution}>{text.solution.InputFive}</div>
            )}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProblemsDesktop);
