import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 8,
    marginBottom: theme.spacing.unit * 12,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing.unit * 2
    }
  },
  title: {
    padding: theme.spacing.unit * 6,
    fontWeight: "bold",
    color: "#FCB348",
    textAlign: "center",
    fontSize: "2rem"
  },
  first: {
    marginTop: "50px"
  },
  skavenji: {
    color: "#00b6be",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2vw"
    }
  },
  logo: {
    maxHeight: "100px",
    marginLeft: "-20px"
  },
  paragraph: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5vw"
    },
    lineHeight: 1.5
  },
  image: {
    width: "100%"
  }
});

class Skavenji extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} justify="space-around">
        <div className={classes.title}>
          A customer solution built with the denimbo IoT suite
        </div>
        <Grid item container className={classes.first} justify="space-evenly">
          <Grid
            item
            xs={12}
            sm={5}
            container
            direction="column"
            justify="space-evenly"
          >
            <Grid item>
              <a
                href="https://www.skavenji.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={"images/skavenji-full.png"}
                  alt="Skavenji logo"
                  className={classes.logo}
                />
              </a>
            </Grid>
            <Grid item className={classes.paragraph}>
              The Skavenji Box lets everyone contribute to a greener World by
              managing the personal production and efficient use of electricity.
            </Grid>

            <br />
            <Grid item>
              <h2>Testimonial</h2>
            </Grid>
            <Grid item className={classes.paragraph}>
              <p>Gaël Desperriès, CEO of Skavenji, says:</p>
              <p>
                When we started the project, we had an idea of the features we
                wanted to offer in the Skavenji Box, but we had not selected a
                hardware platform for the box, and we definitely were not expert
                in embedded, distributed, or cloud applications.{" "}
              </p>
              <p>
                Denimbo provided an end-to-end framework to build upon, letting
                us focus on our business application. The denimbo IoT suite was
                ready to use, and we ran through several iterations of our
                application before we had the hardware platform, saving a lot of
                time.
              </p>
              <p>
                Our project was made possible thanks to the denimbo IoT suite.
              </p>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <img
              src={"images/skavenjibox.png"}
              alt="Skavenji Box"
              className={classes.image}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Skavenji);
