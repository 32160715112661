import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import text from "./TextContent.js";

const styles = theme => ({
  root: {
    width: "100%",
    paddingTop: "20px"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  details: {
    display: "block"
  }
});

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: null
  };

  handleChange = (panel, label) => (event, expanded) => {
    window.gtag("config", "UA-132067546-1", {
      anonymize_ip: true,
      page_title: "IoT-shopping-list/",
      page_location: window.location.href,
      page_path: "IoT-shopping-list/" + label.props.children
    });
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={this.handleChange("panel1", text.question.TabOne)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.heading}>{text.question.TabOne}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {text.solution.InputOne}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={this.handleChange("panel2", text.question.TabTwo)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.heading}>{text.question.TabTwo}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {text.solution.InputTwo}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={this.handleChange("panel3", text.question.TabThree)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.heading}>{text.question.TabThree}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {text.solution.InputThree}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel4"}
          onChange={this.handleChange("panel4", text.question.TabFour)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.heading}>{text.question.TabFour}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {text.solution.InputFour}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={expanded === "panel5"}
          onChange={this.handleChange("panel5", text.question.TabFive)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.heading}>{text.question.TabFive}</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {text.solution.InputFive}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ControlledExpansionPanels);
