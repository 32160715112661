import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

import CustomButton from "../utils/CustomButton.js";
import OffsetAnchor from "../utils/OffsetAnchor.js";

const styles = theme => ({
  paragraph: {
    textAlign: "center",
    margin: "70px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.3vw"
    }
  },
  image: {
    width: "25%",
    height: "auto"
  },
  button: {
    marginBottom: "30px"
  }
});

class NotFound extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <OffsetAnchor id={this.props.id} />
        <Helmet>
          <title>denimbo - Not Found</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <Grid container justify="space-evenly" pacing={24}>
          <Grid item xs={12} className={classes.paragraph}>
            404 - PAGE NOT FOUND
          </Grid>
          <Grid container justify="center" alignItems="center" item xs={12}>
            <img
              src="./images/denimbo.png"
              alt="denimbo logo"
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} className={classes.paragraph}>
            We are sorry. We cannot find the URL you are looking for
          </Grid>
          <Grid item className={classes.button}>
            <CustomButton link="/" content="Back to Homepage" />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
