import React from "react";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import googleEvent from "../utils/GoogleEvent.js";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        backgroundColor: "transparent",
        borderRadius: 0,
        borderStyle: "solid",
        borderWidth: "3px",
        borderColor: "#4F4F4F",
        color: "#4F4F4F",
        height: 48,
        padding: "0 30px",
        boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)"
      }
    }
  }
});

function CustomButton(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <Button
        href={props.link}
        onClick={() => {
          if (props.onClick) {
            googleEvent("button", "innerButton", props.onClick);
          }
        }}
      >
        {props.content}
      </Button>
    </MuiThemeProvider>
  );
}

export default CustomButton;
