import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Fade from "react-reveal/Fade";

const styles = theme => ({
  card: {
    margin: 10,
    flexGrow: 1,
    boxShadow: "none",
    borderRadius: 10,
    backgroundColor: "white",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.complex
    }),
    "&:hover": {
      boxShadow: "14px 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"
    }
  },
  media: {
    minHeight: 170,
    width: "auto",
    backgroundSize: "contain",
    marginTop: "20px"
  },
  title: {
    color: "#FA8125",
    textAlign: "center",
    minHeight: 56
  },
  content: {
    textAlign: "justify",
    lineHeight: 1.5
  }
});

function MediaCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <Fade bottom distance={"500px"} duration={1500}>
        <CardMedia
          className={classes.media}
          image={props.content.img}
          alt={props.content.alt}
        />
        <CardContent>
          <h2 className={classes.title}>{props.content.title}</h2>
          <List>
            <ListItem disableGutters className={classes.content}>
              {props.content.list[0]}
            </ListItem>
            <ListItem disableGutters className={classes.content}>
              {props.content.list[1]}
            </ListItem>
            <ListItem disableGutters className={classes.content}>
              {props.content.list[2]}
            </ListItem>
          </List>
        </CardContent>
      </Fade>
    </Card>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MediaCard);
