import React from "react";

const object = {
  question: {
    TabOne: <h4>Multiple hardware</h4>,

    TabTwo: <h4>Security and Distributed Software</h4>,

    TabThree: <h4>Edge Computing</h4>,

    TabFour: <h4>Scalability and code migration</h4>,

    TabFive: <h4>Life cycle</h4>
  },
  solution: {
    InputOne: (
      <div>
        <p style={{ color: "#FFA959", fontSize: "larger", fontWeight: "bold" }}>
          Be multi-platform and end-to-end
        </p>
        <div>
          <p>
            A typical IoT application involves microcontrollers embedded into
            small devices, as well as bigger processors for edge gateways, cloud
            servers, and also smartphones, tablets and desktops.
          </p>
          <p>
            Each hardware component comes with dedicated development tools,
            specific operational constraints (resources, security model, overall
            life cycle of the component).
          </p>
          <p>
            A lot of expertise is required to configure and operate each of
            these heterogeneous components, and most projects choose to dedicate
            expert resources to address each kind of components, leading to
            complex team organisations.
          </p>
          <p style={{ fontWeight: "bold" }}>
            The denimbo IoT suite is multi-platform and end-to-end.
            Configuration/Programming of the device, the cloud or the mobile
            services are done in a uniform way, and thus can be performed
            essentially by the same person.
          </p>
        </div>
      </div>
    ),
    InputTwo: (
      <div>
        <p style={{ color: "#FFA959", fontSize: "larger", fontWeight: "bold" }}>
          Be simple
        </p>
        <p>
          Most IoT applications are distributed across servers, clients, and
          gateways.
        </p>
        <p>
          Application developers have to think about security, server and client
          configuration, communication protocols, resilience, deployment methods
          for updated software. Before they can start developing the
          application, they have to select and arrange a consistent set of
          software frameworks to support the application.
        </p>
        <p>
          Then, while making the business application, developers face many
          common problems, such as managing asynchronous communications,
          translating business operations into Web requests, enabling efficient
          two-way communications on a client/server Internet architecture.
        </p>
        <p style={{ fontWeight: "bold" }}>
          The denimbo IoT suite is ready to use, it contains all the essential
          software components that developers need to build their business
          application: a builtin request handler and web server, implicit
          protocol operations offered by the unique multi-tier JavaScript
          compiler, builtin security (pre-configured and easy to manage).
        </p>
        <p style={{ fontWeight: "bold" }}>
          Without being IoT software experts, teams of developers can create a
          solution tailored to their company’s business needs, adapted to
          connected objects, cloud services and mobile.
        </p>
        <p style={{ fontWeight: "bold" }}>
          The all-in-one approach of the denimbo IoT suite hides the underlying
          technical complexity and drastically speeds up time to market for new
          products.
        </p>
      </div>
    ),
    InputThree: (
      <div>
        <p style={{ color: "#FFA959", fontSize: "larger", fontWeight: "bold" }}>
          Be embedded
        </p>
        <p>
          Edge computing is the (good) practice to perform operations on data as
          close as possible to the data source. Edge computing allows to spare
          network and cloud bandwidth, critical algorithms run on the device,
          enabling real time control and offering more precision thanks to
          uncompressed data.
        </p>
        <p>
          Some vendors provide one size fits all pre-programmed devices, with
          limited options to customize the device. For cases where it is
          critical to perform non trivial operations on the device, developers
          must customize the firmware using low level software tools, a long and
          tedious operation for many.
        </p>
        <p style={{ fontWeight: "bold" }}>
          The denimbo IoT suite allows to embed critical business logic
          components within the device and ensure its standalone operation and
          local control from a smartphone or laptop, until the device gets
          synchronised with the Cloud services.
        </p>
      </div>
    ),
    InputFour: (
      <div>
        <p style={{ color: "#FFA959", fontSize: "larger", fontWeight: "bold" }}>
          Be flexible
        </p>
        <p>
          Changes to the architecture are sometimes required to take advantage
          of more powerful hardware on the edge devices, or to limit the
          required bandwidth for data communications. These changes are often
          costly because they potentially require an update of the full software
          stack, and also the rewrite of significant pieces of code to migrate
          some processing from the Cloud to the edge devices.
        </p>
        <p>
          In any case, several parts of the application are affected, requiring
          teams of specialists to work on their own field of expertise and
          coordinate with each other.
        </p>
        <p style={{ fontWeight: "bold" }}>
          The denimbo IoT suite let the business logic run where it is more
          appropriate, in the Cloud, on an IoT or a mobile device. Software
          components easily migrate from the cloud to the device or the other
          way around: one language, one API. Optimizing the architecture is a
          breeze.
        </p>
      </div>
    ),
    InputFive: (
      <div>
        <p style={{ color: "#FFA959", fontSize: "larger", fontWeight: "bold" }}>
          Be agile
        </p>
        <p>
          Updating one feature may induce modifications to the embedded
          firmware, the communication protocol, the server front end, the cloud
          database, and the mobile client application. A lot of software to
          change, many people to involve in the process: not that agile, indeed.
        </p>
        <p style={{ fontWeight: "bold" }}>
          The denimbo IoT suite is designed for small agile teams, who can
          invent, build and deploy new features every few days, not months,
          thanks to the multi-tier JavaScript compiler and multi platform
          runtime.
        </p>
      </div>
    )
  }
};

export default object;
