import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

const question =
  "We use cookies to ensure you get the best experience while browsing our website.";

class MyCookieBanner extends Component {
  render() {
    return (
      <div>
        <Snackbar
          style={{ width: "100%" }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={this.props.openBanner}
          onClose={() => this.props.handleChoice( "agree" )}
          ContentProps={{
            "aria-describedby": "message-id",
            style: {
              maxWidth: "100%"
            }
          }}
          message={ <span id="message-id">{ question }</span> }
          action={[
            <Button
              key="yes"
              color="inherit"
              size="small"
              onClick={ () => this.props.handleChoice( "agree" ) }
            >
              Allow cookies
            </Button>,
            <Button
              key="no"
              color="secondary"
              size="small"
              onClick={ () => this.props.handleChoice( "disagree" ) }
            >
              Decline
            </Button>
          ]}
        />
      </div>
    );
  }
}

export default MyCookieBanner;
