import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const styles = theme => ({
  container: {
    paddingBottom: theme.spacing.unit * 10,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing.unit * 2
    }
  },
  title: {
    padding: theme.spacing.unit * 8,
    fontWeight: "bold",
    color: "#FCB348",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw"
    }
  },
  paragraph: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5vw"
    },
    lineHeight: 1.5
  }
});

class Agility extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} justify="space-around">
        <Fade bottom distance={"200px"} duration={750}>
          <Grid item xs className={classes.title}>
            Agility
          </Grid>
          <Grid item container justify="space-around">
            <Grid item xs={11} sm={7} className={classes.paragraph}>
              <h3>Highly maintainable</h3>
              <p>
                The Skavenji application is easy to maintain and update, as all
                of its parts share Business Logic components, the whole
                application is written in JavaScript with the denimbo API.
              </p>
              <h3>Prototype to Product</h3>
              <p>
                The denimbo tool suite provides a continuous path from the proof
                of concept to the operational product.
              </p>
              <p>
                Early stage prototypes included a hardware simulation layer to
                allow the user facing application and most of the firmware to go
                through several design/development/test iterations even before
                the Hardware was available.
              </p>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    );
  }
}

export default withStyles(styles)(Agility);
