import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { HashLink as Link } from "react-router-hash-link";

const styles = theme => ({
  root: {
    width: "100vw",
    backgroundColor: "#4F4F4F",
    color: "white",
    paddingTop: "10px",
    paddingBottom: "10px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  image: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  listItem: {
    padding: "0.5vw"
  },
  section: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
      marginBottom: "5%"
    }
  },
  link: {
    color: "white",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.1vw"
    },
    textDecoration: "none"
  }
});

class SimpleBottomNavigation extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container justify="center" className={classes.root}>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          xs={12}
          sm={3}
          className={classes.section}
        >
          <img
            src="/images/denimbo.png"
            alt="denimbo logo"
            className={classes.image}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          xs={12}
          sm={3}
          className={classes.section}
        >
          <h4>Contact</h4>
          <a href="mailto:contact@denimbo.com" className={classes.link}>
            contact@denimbo.com
          </a>
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          sm={3}
          className={classes.section}
        >
          <List>
            <ListItem className={classes.listItem}>
              <Link
                to="/#landTop"
                scroll={this.handleScroll}
                className={classes.link}
              >
                Home
              </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Link
                to="/#about"
                scroll={this.handleScroll}
                className={classes.link}
              >
                About denimbo
              </Link>
            </ListItem>

            <ListItem className={classes.listItem}>
              <Link
                to="/#benefits"
                scroll={this.handleScroll}
                className={classes.link}
              >
                Benefits
              </Link>
            </ListItem>

            <ListItem className={classes.listItem}>
              <Link
                to="/#vision"
                scroll={this.handleScroll}
                className={classes.link}
              >
                Vision
              </Link>
            </ListItem>

            <ListItem className={classes.listItem}>
              <Link
                to="/#achievements"
                scroll={this.handleScroll}
                className={classes.link}
              >
                Achievements
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          sm={3}
          className={classes.section}
        >
          <List>
            <ListItem className={classes.listItem}>
              <Link to="/IoT-shopping-list#keyTop" className={classes.link}>
                Get Started
              </Link>
            </ListItem>

            <ListItem className={classes.listItem}>
              <Link to="/example-use#scavTop" className={classes.link}>
                A customer case
              </Link>
            </ListItem>

            <ListItem className={classes.listItem}>
              <Link to="/partners#partTop" className={classes.link}>
                Partners
              </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Link
                to="/legal-and-terms-of-use#legalTop"
                scroll={this.handleScroll}
                className={classes.link}
              >
                Legal Terms of Use
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  }
}

SimpleBottomNavigation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleBottomNavigation);
