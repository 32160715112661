import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import DisplayHomePage from "../homePage/DisplayHomePage.js";
import ShoppingPage from "../shoppingList/ShoppingPage.js";
import UseCase from "../useCase/UseCase.js";
import Partners from "../partners/Partners.js";
import LegalAndTermsOfUse from "../legal/LegalAndTermsOfUse.js";
import NotFound from "./NotFound.js";

class DisplayedPage extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={DisplayHomePage} />
        <Route path="/IoT-shopping-list" component={ShoppingPage} />
        <Route path="/example-use" component={UseCase} />
        <Route path="/partners" component={Partners} />
        <Route path="/legal-and-terms-of-use" component={LegalAndTermsOfUse} />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default DisplayedPage;
