import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";

import LandingPage from "./LandingPage.js";
import About from "./About.js";
import Vision from "./Vision.js";
import Solutions from "./Solutions.js";
import Achievements from "./Achievements.js";
import NewsBanner from "./NewsBanner.js";

import OffsetAnchor from "../utils/OffsetAnchor.js";

const styles = {
  App: {
    flexGrow: 1,
    marginTop: 50,
    backgroundImage: "url(images/linen/linen.png)",
    paddingBottom: "30px"
  }
};

class DisplayHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.App}>
        <Helmet>
          <title>denimbo</title>
          <meta
            name="description"
            content="The denimbo IoT suite makes possible the agile development of device-to-cloud IoT applications by business focused teams."
          />
        </Helmet>
        <OffsetAnchor id="landTop" />
        <NewsBanner />
        <LandingPage />
        <Fade bottom distance={"100px"} duration={500}>
          <About id={"about"} />
          <Solutions id={"benefits"} />
          <Vision id={"vision"} />
          <Achievements id={"achievements"} />
        </Fade>
      </div>
    );
  }
}

export default withStyles(styles)(DisplayHomePage);
