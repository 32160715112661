import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  paper: {
    cursor: "default",
    minHeight: "35vw",
    paddingTop: "30px",
    padding: theme.spacing.unit * 4,
    margin: theme.spacing.unit * 10,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginBottom: theme.spacing.unit
    },
    flexGrow: 1,
    borderRadius: 0,
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    transition: theme.transitions.create(["all"], {
      duration: theme.transitions.duration.standard
    }),
    "&:hover": {
      boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"
    }
  }
});

class MyPaper extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper} elevation={1} square={true}>
        {this.props.children}
      </Paper>
    );
  }
}

export default withStyles(styles)(MyPaper);
