import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

const styles = theme => ({
  container: {
    backgroundColor: "#F6F4F4",
    paddingBottom: theme.spacing.unit * 10,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing.unit * 2
    }
  },
  title: {
    padding: theme.spacing.unit * 8,
    fontWeight: "bold",
    color: "#FCB348",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw"
    }
  },
  paragraph: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5vw"
    },
    lineHeight: 1.5
  }
});

class Design extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} justify="space-around">
        <Fade bottom distance={"200px"} duration={750}>
          <Grid item xs className={classes.title}>
            Open design
          </Grid>
          <Grid item container justify="space-around">
            <Grid
              item
              xs={12}
              sm={5}
              container
              direction="column"
              justify="space-between"
            >
              <img
                src={"images/skavenji_design.png"}
                alt="Illustration of the Skavenji user interface design on laptops and smartphones"
                style={{ width: "100%", height: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <h2>Compatible with all smart devices</h2>
              <p className={classes.paragraph}>
                The Skavenji application supports any device running a web
                browser.
              </p>
              <h2>Standard compliant</h2>
              <p className={classes.paragraph}>
                The application is built on standard protocols and API, easily
                integrating some of the best front-end and back-end open source
                libraries which cooperate with the proprietary multitier
                JavaScript compiler to deliver a nice looking, responsive UI for
                smartphones, tablets, and computers.
              </p>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    );
  }
}

export default withStyles(styles)(Design);
