import React, { Component } from "react";
import { withRouter } from "react-router";

import MyNavbar from "./Components/layout/MyNavbar.js";
import DisplayedPage from "./Components/layout/DisplayedPage.js";
import Footer from "./Components/layout/Footer.js";
import MyCookieBanner from "./Components/utils/MyCookieBanner";

function deleteAllCookies() {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openBanner: false
    };
  }

  handleChoice = choice => {
    this.setState({
      openBanner: false
    });

    localStorage.setItem("cookieChoice", choice);

    if (choice === "agree") {
      localStorage.setItem("expiration", Date.now() + 3153e7);
      window["ga-disable-UA-132067546-1"] = false;
    } else {
      localStorage.setItem("expiration", Date.now() + 2628e6);
      window["ga-disable-UA-132067546-1"] = true;
      deleteAllCookies();
    }
  };

  componentDidMount() {
    if (!localStorage.getItem("cookieChoice")) {
      this.setState({ openBanner: true });
    }
  }

  componentDidUpdate(prevProps) {
    const gtag = window.gtag;

    if (prevProps.location.pathname === this.props.location.pathname) {
      return;
    }
    if (prevProps.history.action === "PUSH" && typeof gtag === "function") {
      gtag("config", "UA-132067546-1", {
        anonymize_ip: true,
        page_title: this.props.location.pathname,
        page_location: window.location.href,
        page_path: this.props.location.pathname
      });
    }
  }

  render() {
    return (
      <div>
        <MyNavbar />
        <MyCookieBanner
          openBanner={this.state.openBanner}
          handleChoice={this.handleChoice}
        />
        <DisplayedPage />
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
