import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import CustomButton from "../utils/CustomButton.js";

const styles = theme => ({
  section: {
    backgroundImage: "url(images/citrus_peel.jpg)",
    backgroundSize: "cover",
    width: "100%",
    minHeight: "35vw",
    [theme.breakpoints.down("sm")]: {
      minHeight: "45vw"
    },
    padding: theme.spacing.unit * 4
  },
  baseline: {
    color: "white",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5vw"
    }
  }
});

class LandingPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classes.section}
        alignItems="flex-end"
        justify="center"
        id={this.props.id}
      >
        <Grid item xs={12} className={classes.baseline}>
          <h1>denimbo IoT suite</h1>
          <h2 style={{ color: "gray" }}>ONE Software for Smart IoT</h2>
        </Grid>
        <CustomButton
          link="mailto:contact@denimbo.com"
          content={"Contact"}
          onClick="landingContact"
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(LandingPage);
