import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";

import OffsetAnchor from "../utils/OffsetAnchor.js";
import ShoppingDesktop from "./ShoppingDesktop";
import ShoppingMobile from "./ShoppingMobile";

const styles = theme => ({
  title: {
    fontWeight: "bold",
    paddingTop: "50px",
    paddingBottom: "10px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "2vw"
    }
  },
  desktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block"
    }
  },
  mobile: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
});

class ShoppingPage extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Helmet
          script={[{
            "type": "application/ld+json",
            "innerHTML": `{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "IoT_shopping_list",
                "item": "https://denimbo.com/IoT-shopping-list"
              }]
            }`
          }]}>
          <title>denimbo - The IoT shopping list</title>
          <meta
            name="description"
            content="Key points one should address and have in mind before starting to develop an IoT application"
          />
        </Helmet>
        <OffsetAnchor id="keyTop" />
        <p className={classes.title}>
          <span style={{ color: "#FF6600" }}>The IoT shopping list:</span> Five
          key points one should address
        </p>
        <div className={classes.desktop}>
          <ShoppingDesktop />
        </div>
        <div className={classes.mobile}>
          <ShoppingMobile />
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(ShoppingPage);
