import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { HashLink as Link } from "react-router-hash-link";

import CustomButton from "../utils/CustomButton.js";
import MediaCard from "../utils/MediaCard.js";
import OffsetAnchor from "../utils/OffsetAnchor.js";
import MyPaper from "../utils/MyPaper.js";

// Icon made by [author link] from www.flaticon.com

class Solutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      a: {
        img: "images/cloud.png",
        alt: "Icon representing a cloud",
        title: "A multitier JavaScript framework",
        list: [
          "Speed up time to market thanks to a flexible and scalable framework",
          "One language, one API: Business objects are shared accross components",
          "Run the business logic where it is the more appropriate, in the cloud, on the mobile, or directly on the fully programmable edge device"
        ]
      },

      b: {
        img: "images/developer.png",
        alt: "Icon representing a desktop",
        title: "For business focused developers",
        list: [
          "No need to be expert of distributed applications or embedded software",
          "Ready to use, full-stack technology, down to the edge device",
          "Built in security and communication"
        ]
      },
      c: {
        img: "images/smartphone.png",
        alt: "Icon representing a mobile phone",
        title: "To design and deploy state of the art solutions",
        list: [
          "Build cost effective products: no compromise on features or quality",
          "Agile tools let design, implement, and deploy new features as often as desired",
          "Rich user experience in all circumstances: cloud or direct device control"
        ]
      }
    };
  }

  render() {
    return (
      <Fragment>
        <OffsetAnchor id={this.props.id} />
        <MyPaper>
          <Grid container justify="center" spacing={16}>
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard content={this.state.a} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard content={this.state.b} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <MediaCard content={this.state.c} />
            </Grid>
            <Grid container justify="center">
              <Grid item>
                <Link
                  to="/IoT-shopping-list#keyTop"
                  style={{ textDecoration: "none" }}
                >
                  <CustomButton
                    link=""
                    content="View More"
                    onClick="solutionsToGoingIoT"
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </MyPaper>
      </Fragment>
    );
  }
}

export default Solutions;
