import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import CustomButton from "../utils/CustomButton.js";
import OffsetAnchor from "../utils/OffsetAnchor.js";

const styles = theme => ({
  paper: {
    minHeight: "35vw",
    paddingTop: 30,
    paddingBottom: theme.spacing.unit * 4,
    margin: theme.spacing.unit * 10,
    backgroundImage: "url(images/bg.jpg)",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginBottom: theme.spacing.unit
    }
  },
  paragraph: {
    lineHeight: 2,
    margin: "50px",
    marginBottom: "90px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8vw"
    }
  },
  span: {
    color: "#FCB348",
    transition: "color 0.5s",
    "&:hover": {
      color: "#FC6716"
    }
  }
});

class About extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <OffsetAnchor id={this.props.id} />
        <Paper className={classes.paper} elevation={1} square={true}>
          <Grid container justify="space-evenly" pacing={24}>
            <Grid item xs={12} className={classes.paragraph}>
              <p>
                Looking for a simple, business focused way to build connected
                solutions?
              </p>
              <p>
                <span className={classes.span}>
                  The denimbo IoT suite enables the agile development of
                  device-to-cloud applications
                </span>{" "}
                thanks to our <br />
                all-in-one approach powered by a multitier JavaScript framework.
              </p>
              <p>
                Without being IoT specialists, create a solution tailored to
                your company’s business needs , adapted to connected objects,
                cloud services and mobile devices.
              </p>
            </Grid>
            <Grid item>
              <CustomButton
                link="#benefits"
                content="View More"
                onClick="aboutToBenefits"
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(About);
