import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import OffsetAnchor from "../utils/OffsetAnchor.js";
import MyPaper from "../utils/MyPaper.js";

const styles = theme => ({
  root: {
    textAlign: "justify",
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3
  },
  title1: {
    textAlign: "center"
  },
  title2: {
    textAlign: "center",
    paddingTop: theme.spacing.unit * 3
  }
});

class LegalAndTermsOfUse extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <OffsetAnchor id="legalTop" />
        <MyPaper className={classes.root}>
          <h1 className={classes.title1}>Legal</h1>
          <p>
            DENIMBO est une société par actions simplifiée unipersonnelle au
            capital de 40 000,00 €<br /> RCS: 840 242 341
            <br /> Siret: 840 242 341 00013
            <br />
            Intercommunity VAT: FR 63 840242341
          </p>
          <p>
            Head Office
            <br />
            Business Pôle
            <br />
            1047 route des Dolines
            <br />
            06560 VALBONNE
          </p>
          <a href="mailto:contact@denimbo.com">contact@denimbo.com</a>
          <p>
            WEBSITE
            <br />
            denimbo.com
            <br />
            Publication director: M. Vincent PRUNET
            <br />
            Hosting: OVH, 2 rue Kellermann - 59100 Roubaix - France
            <br />
          </p>
          <h1 className={classes.title2}>Terms of use</h1>
          <p>
            The purpose of this document is to define the terms and conditions
            under which, on the one hand, SASU DENIMBO, hereinafter referred to
            as DENIMBO, makes available to its users the website, and the
            services available on the website and on the other hand, the way in
            which users access the website and use its services.
            <br />
            Any connection to the website is subject to compliance with these
            conditions.
          </p>
          <p>
            Regarding users, the mere access to the DENIMBO website at the
            following URL denimbo.com implies the acceptance of all the
            conditions described below.
          </p>
          <h3>Intellectual property</h3>
          <p>
            The general structure of the website denimbo.com, as well as the
            texts, graphics, images, sounds and videos composing it, are the
            property of the publisher or its partners. Any representation and/or
            reproduction and/or partial or total exploitation of the contents
            and services offered by the website denimbo.com, by any means
            whatsoever, without the prior written permission of denimbo.com
            and/or its partners is strictly prohibited and could constitute an
            infringement within the meaning of articles L 335-2 and following of
            the Code of the intellectual property.
          </p>
          <h3>Hypertext links</h3>
          <p>
            The denimbo.com website may contain hypertext links to other sites
            on the Internet. Following those links makes you leave denimbo.com.
          </p>
          <p>
            It is possible to create a link to the presentation page of this
            website without the express permission of DENIMBO. No authorization
            or request for prior information may be required by the publisher
            for a website that wishes to establish a link to the DENIMBO’s
            website. However, this website should be displayed in a new browser
            window. However, DENIMBO reserves the right to request the deletion
            of a link that it deems non-compliant with the purpose of the
            website denimbo.com.
          </p>
          <h3>Responsibility of the publisher</h3>
          <p>
            The information and/or documents appearing on this website and/or
            accessible through this website come from sources considered to be
            reliable.
          </p>
          <p>
            However, this information and/or documents may contain technical
            inaccuracies and typographical errors.
            <br />
            DENIMBO reserves the right to correct them as soon as these errors
            are brought to its attention.
          </p>
          <p>
            It is strongly recommended to check the accuracy and relevance of
            the information and/or documents made available on this website.
          </p>
          <p>
            The information and/or documents available on this website are
            subject to change at any time, and may have been updated. In
            particular, they may have been updated between the time of their
            download and the moment when the user reads them.
          </p>
          <p>
            The use of information and/or documents available on this website is
            under the full and sole responsibility of users, who assume all the
            consequences that may ensue, without DENIMBO can be sought as such,
            and without recourse against the latter.
          </p>
          <p>
            DENIMBO can not be held responsible for any damage whatsoever
            arising from the interpretation or use of information and/or
            documents available on this website.
          </p>
          <h3>Website access</h3>
          <p>
            The publisher endeavors to allow access to the site permanently,
            except in case of force majeure or an event beyond the control of
            DENIMBO, and subject to possible breakdowns and maintenance
            operations necessary for the proper functioning of the site and
            services.
          </p>
          <p>
            Therefore, DENIMBO can not guarantee availability of the website
            and/or services, reliability of transmissions and performance in
            terms of response time or quality. No online nor phone technical
            support is provided to users in such case.
          </p>
          <p>
            The responsibility of the publisher can not be engaged in case of
            impossibility of access to this site and/or use of the services.
          </p>
          <p>
            In addition, DENIMBO may be required to interrupt the site or part
            of the services, at any time without prior notice, all without
            entitlement to compensation. Users acknowledge and agree that
            DENIMBO is not responsible for interruptions, and any consequences
            that may arise for users or any third party.
          </p>
          <h3>Changes to terms and conditions of use</h3>
          <p>
            DENIMBO reserves the right to modify, at any time and without
            notice, the present conditions of use in order to adapt them to the
            evolutions of the site and/or its exploitation.
          </p>
          <h3>Internet usage policy</h3>
          <p>
            The user declares to accept the characteristics and limits of the
            Internet, and in particular recognizes that:
            <br />
            DENIMBO assumes no responsibility for the services accessible
            through the Internet and has no control whatsoever over the nature
            and characteristics of the data that may pass through its host.
          </p>
          <p>
            The user acknowledges that the data circulating on the Internet are
            not protected in particular against possible embezzlements. The
            presence of the logo http://denimbo.com/images/denimbo.png
            establishes a simple presumption of validity. The communication of
            any information deemed by the user to be of a sensitive or
            confidential nature is at the own risk of the user.
          </p>
          <p>
            The user acknowledges that the data circulating on the Internet can
            be regulated in terms of use or be protected by a property right.
          </p>
          <p>
            The user is solely responsible for the use of the data he/she
            consults, enquires and transfers on the Internet.
          </p>
          <p>
            The user acknowledges that DENIMBO does not have any control over
            the content of the services accessible on the Internet.
          </p>
          <h3>Applicable law</h3>
          <p>
            Both this website and the terms and conditions of its use are
            governed by French law, regardless of the place of use. In case of
            any dispute, and after the failure of any attempt to find an
            amicable solution, the French courts will be competent to hear this
            dispute.
          </p>
          <p>
            For any question regarding these terms of use of the site, you can
            write to us at the following address:
            <br />
            <a href="mailto:webmaster@denimbo.com">webmaster@denimbo.com</a>
          </p>
        </MyPaper>
      </div>
    );
  }
}

export default withStyles(styles)(LegalAndTermsOfUse);
