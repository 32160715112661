import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import OffsetAnchor from "../utils/OffsetAnchor.js";
import { Helmet } from "react-helmet";

const styles = theme => ({
  container: {
    padding: theme.spacing.unit
  },
  title: {
    paddingTop: theme.spacing.unit * 14,
    paddingBottom: 0,
    fontWeight: "bold",
    color: "#FCB348",
    textAlign: "center",
    fontSize: "2rem"
  },
  item: {
    paddingTop: theme.spacing.unit * 6
  },
  image: {
    maxHeight: "130px",
    maxWidth: "100%",
    marginTop: "0.67rem"
  },
  brands: {
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 2
  },
  bottom: {
    paddingBottom: theme.spacing.unit * 10
  },
  legal: {
    fontSize: "0.6em",
    margin: 0
  }
});

class Partners extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Helmet
          script={[
            {
              type: "application/ld+json",
              innerHTML: `{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Partners",
                "item": "https://denimbo.com/partners"
              }]
            }`
            }
          ]}
        >
          <title>denimbo - Partners</title>
          <meta
            name="description"
            content="Institutional and private partners that support denimbo in its activity, the diffusion of a software solution for the development of IoT applications."
          />
        </Helmet>
        <OffsetAnchor id="partTop" />
        <Grid container className={classes.container} justify={"center"}>
          <Grid
            item
            xs={12}
            className={classNames(classes.title, classes.item)}
          >
            <div>Our technology runs on</div>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            justify={"space-between"}
            xs={10}
            className={classes.brands}
          >
            <Grid container item justify={"center"} xs={10} sm={4} md={3}>
              <a
                href="http://hop.inria.fr/home/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/hop.js.png"
                  alt="Hop.js Logo"
                />
              </a>
            </Grid>

            <Grid container item justify={"center"} xs={10} sm={4} md={3}>
              <a
                href="https://nodejs.org/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/node.js_logo.png"
                  alt="Node.js Logo"
                />
              </a>
            </Grid>

            <Grid container item justify={"center"} xs={10} sm={4} md={3}>
              <a
                href="http://www.raspberrypi.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/raspberry_pi.png"
                  alt="Raspberry Pi Logo"
                />
              </a>
            </Grid>

            <Grid container item justify={"center"} xs={10} sm={4} md={3}>
              <a
                href="https://aws.amazon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/aws.png"
                  alt="Amazon Web Services Logo"
                />
              </a>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.title}>
            <div>Affiliations</div>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            xs={8}
            className={classes.item}
          >
            <Grid item xs={12} sm={4} md={3}>
              <a
                href="https://www.pole-scs.org/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/pole_scs.png"
                  alt="Pole SCS Logo"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <h1>SCS Cluster</h1>
              <p>
                denimbo is member of the SCS world-class competitiveness
                cluster, a leading european ecosystem in security and digital
                identities technologies, mobile networks and services, IoT and
                contactless.
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            xs={8}
            className={classes.item}
          >
            <Grid item xs={12} sm={4} md={3}>
              <a
                href="http://www.telecom-valley.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/telecom_valley_adherent.png"
                  alt="Telecom Valley Logo"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <h1>Telecom Valley</h1>
              <p>
                Telecom Valley is the first network of companies dedicated to
                Digital animation on the French Riviera with a core activity in
                Nice and Sophia Antipolis.
              </p>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.title}>
            <div>Supporting Partners</div>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            xs={8}
            className={classes.item}
          >
            <Grid item xs={12} sm={4} md={3}>
              <a
                href="http://en.incubateurpacaest.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/IPE.jpeg"
                  alt="IPE Logo"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <h1>PACA Est Incubator</h1>
              <p>
                denimbo is supported by PACA Est Incubator, located in Sophia
                Antipolis, France
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            xs={8}
            className={classes.item}
          >
            <Grid item xs={12} sm={4} md={3}>
              <a
                href="https://www.inria.fr/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/INRIA.png"
                  alt="Inria Logo"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <h1>Inria</h1>
              <p>
                denimbo is supported by Inria Technology Transfer Programme.
                denimbo is the exclusive licensee of the Inria hop.js multi-tier
                JavaScript compilers and runtime for commercial use.{" "}
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            xs={8}
            className={classes.item}
          >
            <Grid item xs={12} sm={4} md={3} >
              <a
                href="http://univ-cotedazur.fr/en/eur/ds4h/innovation/funded-projects"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/ucnlab.png"
                  alt="UCNLab Logo"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <h1>Labex UCN@Sophia</h1>
              <p>
                The Labex UCN@Sophia, which led to the pluridisciplinary
                research program of the graduate school "Digital Systems for
                Humans" of Université Côte d'Azur, has funded the development of
                denimbo pilot cases operated by Medical research teams at the
                Nice Hospital
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            item
            spacing={16}
            direction="row"
            xs={8}
            className={classNames(classes.item, classes.bottom)}
          >
            <Grid item xs={12} sm={4} md={3}>
              <a
                href="https://www.bpifrance.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={classes.image}
                  src="images/bpi-france.png"
                  alt="bpi france Logo"
                />
              </a>
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <h1>bpi france</h1>
            </Grid>
          </Grid>

          <Grid container>
            <p className={classes.legal}>
              Node.js is a trademark of Joyent, Inc. and is used with its
              permission. We are not endorsed by or affiliated with Joyent.
              <br />
              Raspberry Pi is a trademark of the Raspberry Pi Foundation.
            </p>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Partners);
