import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

import MyPaper from "../utils/MyPaper.js";
import OffsetAnchor from "../utils/OffsetAnchor.js";

const styles = theme => ({
  title: {
    color: "#FF6600"
  },
  text: {
    textAlign: "left",
    lineHeight: 1.5,
    fontSize: "0.9rem",
    marginTop: "3vw"
  },
  highlight: {
    fontStyle: "italic",
    color: "#FF8822",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem"
    },
    transition: "color 0.5s",
    "&:hover": {
      color: "#FF6600"
    }
  },
  visionLink: {
    color: "inherit"
  },
  image: {
    width: "100%"
  }
});

class Vision extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <OffsetAnchor id={this.props.id} />
        <MyPaper>
          <Grid container justify={"center"}>
            <Fade bottom distance={"200px"} duration={1000}>
              <h2 className={classes.title}>
                Our vision: Turn business devices into connected objects,
                easily!
              </h2>
              <Grid container item xs={12} justify={"flex-start"}>
                <Grid item xs={12} sm={8}>
                  <div className={classes.text}>
                    <Fade bottom distance={"200px"} duration={1000}>
                      <p>
                        The use of connected objects is rapidly expanding in our
                        daily life and throughout all areas of activity. To
                        remain competitive, companies are now seeking to tap
                        into the vast potential of Internet of Things (IoT).
                        They must tackle this new field, in much the same way
                        they had to learn to make the most of the Web several
                        years ago.
                      </p>
                      <p className={classes.highlight}>
                        And it’s happening now: we estimate that two out of
                        three companies are in need to design connected things
                        in the short term.
                      </p>
                      <h4>An example?</h4>
                      <p>
                        Take a weight scale, connected to a cloud service that
                        records health parameters, and allows the user to check
                        his personal data on a mobile phone. To offer this
                        service which seems simple, the device manufacturer had
                        to create a complex software that controls the object
                        (the scale), processes the data in the cloud and
                        presents the records on the smartphone.
                      </p>
                      <p>
                        Companies want to connect in a similar way medical
                        devices, robots, energy monitoring equipments,
                        vehicles...
                      </p>
                    </Fade>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <img
                    src={"images/wifi_bleu_gris.png"}
                    alt="Connected scale"
                    className={classes.image}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} justify={"flex-end"}>
                <Grid item xs={12}>
                  <div className={classes.text}>
                    <Fade bottom distance={"200px"} duration={1000}>
                      <p>
                        Most companies, however, don’t have the skills and
                        knowhow to conceive this type of applications.{" "}
                      </p>
                      <p className={classes.highlight}>
                        According to a{" "}
                        <a
                          className={classes.visionLink}
                          href="https://newsroom.cisco.com/press-release-content?articleId=1847422"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          recent study
                        </a>{" "}
                        from Cisco, three quarters of IoT projects fail: the
                        main reason invoked is the lack of expertise of the
                        companies that launch them.
                      </p>
                      <h4>
                        What if it was easy? As easy as it is today to create a
                        business website…
                      </h4>
                      <p>
                        That’s what the denimbo IoT suite is proposing with an
                        all-in-one approach that hides the underlying technical
                        complexity. Without being IoT specialists, teams of
                        developers can create a solution tailored to their
                        company’s business needs, adapted to connected objects,
                        cloud services and mobile devices.
                      </p>
                      <p>
                        The denimbo IoT suite is designed for agile teams,
                        enabling rapid prototyping and the continual deployment
                        of new functionalities.
                      </p>
                    </Fade>
                  </div>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </MyPaper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Vision);
